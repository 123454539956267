import { createRouter, createWebHistory } from "vue-router";

import Layout from "../layout/CA_Layout.vue"; //layout

const routes = [
  {
    path: "/ca_login",
    name: "Login",
    component: () => import("../views/v_1_login/index.vue"),
    hidden: true,
  },
  {
    path: "/ca_terms",
    name: "ca_terms",
    component: () => import("../views/v_1_login/ca_terms.vue"),
  },

  {
    path: "/",
    component: Layout,
    redirect: "/ca_home",
    children: [
      {
        path: "/ca_home",
        name: "Dashboard",
        component: () => import("../views/v_2_dashboard/dashview.vue"),
      },
      {
        path: "/ca_scene",
        name: "SceneMonitorDashboard",
        component: () => import("../views/v_2_dashboard/dashbranchmonitor.vue"),
      },
      {
        path: "/ca_head",
        name: "HeadMonitorDashboard",
        component: () => import("../views/v_2_dashboard/dashboarda.vue"),
      },

      {
        path: "/ca_test",
        name: "QRTest",
        component: () => import("../views/v_2_dashboard/QR_Test.vue"),
      },
      {
        path: "/ca_documentTotal",
        name: "ca_documentTotal",
        component: () => import("../views/v_2_dashboard/documentTotal.vue"),
      },
      {
        path: "/ca_constructionTotal",
        name: "ca_constructionTotal",
        component: () => import("../views/v_2_dashboard/constructionTotal.vue"),
      },
      {
        path: "/ca_koshaTotalSearch",
        name: "ca_koshaTotalSearch",
        component: () => import("../views/v_2_dashboard/koshaTotalSearch.vue"),
      },
    ],
  },

  {
    path: "/ca_plan",
    component: Layout,
    name: "Plan",
    children: [
      {
        path: "plan_list",
        component: () => import("../views/v_3_plan/plan_list.vue"),
        name: "plan_list",
      },
      {
        path: "plan_create",
        component: () => import("../views/v_3_plan/plan_create.vue"),
      },
      {
        path: "plan_excute",
        component: () => import("../views/v_3_plan/plan_excute.vue"),
      },
      {
        path: "plan_add",
        component: () => import("../views/v_3_plan/plan_add/plan_add.vue"),
        name: "plan_add",
      },
      {
        path: "plan_more",
        component: () => import("../views/v_3_plan/plan_add/plan_more_page.vue"),
        name: "plan_more",
      },
      {
        path: "plan_more_month",
        component: () => import("../views/v_3_plan/plan_add/plan_more_month_page.vue"),
        name: "plan_more_month",
      },
    ],
  },

  {
    path: "/ca_work",
    component: Layout,
    name: "Work",
    children: [
      {
        path: "work_risk_evalution",
        component: () => import("../views/v_4_work/work_risk_evalution.vue"),
        name: "work_risk_evalution",
      },
      {
        path: "work_edu_tranning",
        component: () => import("../views/v_4_work/work_edu_tranning.vue"),
        name: "work_edu_tranning",
      },
      {
        path: "work_branch_activity",
        component: () => import("../views/v_4_work/work_branch_activity.vue"),
        name: "work_branch_activity",
      },
      {
        path: "work_head_activity",
        component: () => import("../views/v_4_work/work_head_activity.vue"),
        name: "work_head_activity",
      },
      {
        path: "check_head_activity",
        component: () => import("../views/v_4_work/check_head_activity.vue"),
        name: "check_head_activity",
      },

    ],
  },

  {
    path: "/ca_policy",
    component: Layout,
    name: "Policy",
    children: [
      {
        path: "policy_list",
        component: () => import("../views/v_5_policy/policy_list.vue"),
        name: "policy_list",
      },
      {
        path: "policy_create",
        component: () => import("../views/v_5_policy/policy_create.vue"),
      },
      {
        path: "policy_update",
        component: () => import("../views/v_5_policy/policy_update.vue"),
      },

      {
        path: "policy_operate",
        component: () => import("../views/v_5_policy/policy_operate.vue"),
        name: "policy_operate",
      },
      {
        path: "test1",
        component: () => import("../views/v_5_policy/policyplan/test1.vue"),
      },
    ],
  },

  {
    path: "/policy_list_print",
    component: () => import("../views/v_5_policy/print/policy_list_print_template.vue"),
    name: "policy_list_print",
  },
  {
    path: "/policy_operate_print",
    component: () => import("../views/v_5_policy/print/policy_operate_print_template.vue"),
    name: "policy_operate_print",
  },

  {
    path: "/ca_management",
    component: Layout,
    name: "Management",
    children: [
      {
        path: "management_list",
        component: () => import("../views/v_6_management/management_list.vue"),
        name: "management_list",
      },
      {
        path: "management_operate",
        component: () => import("../views/v_6_management/management_operate.vue"),
        name: "management_operate",
      },
    ],
  },
  {
    path: "/management_list_print",
    component: () => import("../views/v_6_management/print/management_list_print_template.vue"),
    name: "management_list_print",
  },
  {
    path: "/management_operate_print",
    component: () => import("../views/v_6_management/print/management_operate_print_template.vue"),
    name: "management_operate_print",
  },

  {
    path: "/ca_board",
    component: Layout,
    name: "Board",
    children: [
      {
        path: "board_head",
        component: () => import("../views/v_7_board/head/board_head.vue"),
        name: "board_head",
      },
      {
        path: "board_branch",
        component: () => import("../views/v_7_board/field/board_field.vue"),
        name: "board_branch",
      },
      {
        path: "board_comm",
        component: () => import("../views/v_7_board/comm/board_comm.vue"),
        name: "board_comm",
      },
      {
        path: "board_editor/:targetDocumentId?",
        component: () => import("../views/v_7_board/head/board_editor.vue"),
        name: "board_editor",
      },
      {
        path: "board_editor_field/:targetDocumentId?",
        component: () => import("../views/v_7_board/field/board_editor.vue"),
        name: "board_editor_field",
      },
      {
        path: "board_editor_comm/:targetDocumentId?",
        component: () => import("../views/v_7_board/comm/board_editor.vue"),
        name: "board_editor_comm",
      },
      {
        path: "board_head_branch",
        component: () => import("../views/v_7_board/field/board_headview_field.vue"),
        name: "board_head_branch",
      },
    ],
  },

  {
    path: "/ca_kosha",
    component: Layout,
    name: "Kosha",
    redirect: "/ca_kosha/kosha_doclist",
    children: [
      {
        path: "kosha_emanual",
        component: () => import("../views/v_8_kosha/kosha_emanual.vue"),
        name: "kosha_emanual",
      },
      {
        path: "kosha_head_com",
        component: () => import("../views/v_8_kosha/kosha_head_com.vue"),
        name: "kosha_head_com",
      },
      {
        path: "kosha_branch_com",
        component: () => import("../views/v_8_kosha/kosha_branch_com.vue"),
        name: "kosha_branch_com",
      },
      {
        path: "kosha_head_com_WAIT",
        component: () => import("../views/v_8_kosha/kosha_head_com_WAIT.vue"),
        name: "kosha_head_com_WAIT",
      },
      {
        path: "kosha_branch_com_WAIT",
        component: () => import("../views/v_8_kosha/kosha_branch_com_WAIT.vue"),
        name: "kosha_branch_com_WAIT",
      },
      {
        path: "kosha_doclist",
        component: () => import("../views/v_8_kosha/kosha_doclist.vue"),
        name: "kosha_doclist",
      },
      {
        path: "safety_health_total",
        component: () => import("../views/v_8_kosha/table/list2_temp/total/total.vue"),
        name: "safety_health_total",
      },
      {
        path: "education_total",
        component: () => import("../views/v_8_kosha/table/list5_temp/total/total.vue"),
        name: "education_total",
      },
      {
        path: "work_field_document_total",
        component: () => import("../views/v_8_kosha/work_field_document_total.vue"),
        name: "work_field_document_total",
      },
      {
        path: "work_field_document_total_inner",
        component: () => import("../views/v_8_kosha/work_field_document_total_inner.vue"),
        name: "work_field_document_total_inner",
      },
      {
        path: "list3_board",
        component: () => import("../views/v_8_kosha/table/list3_temp/2_Board.vue"),
        name: "list3_board",
      },

      {
        path: "list6_board",
        component: () => import("../views/v_8_kosha/table/list6_temp/2_Board.vue"),
        name: "list6_board",
      },
      {
        path: "list_organization_board",
        component: () => import("../views/v_8_kosha/table/list_organization_temp/2_Board.vue"),
        name: "list_organization_board",
      },
      {
        path: "list8_board",
        component: () => import("../views/v_8_kosha/table/list8_temp/2_Board.vue"),
        name: "list8_board",
      },
      {
        path: "list_intest_board_inner",
        component: () => import("../views/v_8_kosha/table/list_intest/2_Board_Inner.vue"),
        name: "list_intest_board_inner",
      },
      {
        path: "list_intest_field_board",
        component: () => import("../views/v_8_kosha/table/list_intest/2_Board_Field.vue"),
        name: "list_intest_field_board",
      },
      {
        path: "list_monitor_board_inner",
        component: () => import("../views/v_8_kosha/table/list_monitor/2_Board_Inner.vue"),
        name: "list_monitor_board_inner",
      },
      {
        path: "list_monitor_board_Total",
        component: () => import("../views/v_8_kosha/table/list_monitor/2_Board_Total.vue"),
        name: "list_monitor_board_Total",
      },
      {
        path: "list_monitor_board_Field",
        component: () => import("../views/v_8_kosha/table/list_monitor/2_Board_Field.vue"),
        name: "list_monitor_board_Field",
      },
      {
        path: "kosha_list_board",
        component: () => import("../views/v_8_kosha/kosha_Board_Main.vue"),
        name: "kosha_list_board",
      },
      {
        path: "board_inspection_total",
        component: () => import("../views/v_8_kosha/table/list7_temp/Total.vue"),
        name: "board_inspection_total",
      },
    ],
  },
  {
    path: "/print_main",
    component: () => import("../views/v_8_kosha/table/print/print_main.vue"),
    name: "/print_main",
  },
  {
    path: "/list8_view",
    component: () => import("../views/v_8_kosha/table/list8_temp/ViewModeBoard.vue"),
    name: "list8_view",
  },
  {
    path: "/kosha_linkBoard",
    component: () => import("../views/v_8_kosha/kosha_LinkBoard.vue"),
    name: "kosha_linkBoard",
  },
  {
    path: "/kosha_Danger_Window",
    component: () => import("../views/v_8_kosha/kosha_Danger_Window.vue"),
    name: "kosha_Danger_Window",
  },
  {
    path: "/kosha_approval_group_Window",
    component: () => import("../views/v_10_approval/Risk_Group_Board/newWindow.vue"),
    name: "kosha_approval_group_Window",
  },

  // PDF 뷰어
  {
    path: "/truesafe_PDF",
    component: () => import("../components/pdfView/pdfViewer.vue"),
    name: "truesafe_PDF",
  },

  {
    path: "/kosha_linkList",
    component: () => import("../views/v_8_kosha/kosha_LinkView.vue"),
    name: "kosha_linkList",
  },
  {
    path: "/kosha_orgChartWindow",
    component: () => import("../views/v_8_kosha/table/list_orgchart/orgChartWindow.vue"),
    name: "kosha_orgChartWindow",
  },
  {
    path: "/kosha_print1",
    component: () => import("../views/v_8_kosha/table/list1_temp/print.vue"),
    name: "/kosha_print1",
  },
  {
    path: "/kosha_print2",
    component: () => import("../views/v_8_kosha/table/list2_temp/print.vue"),
    name: "/kosha_print2",
  },
  {
    path: "/fieldkosha_print2",
    component: () => import("../views/v_8_kosha/table/list2_temp/print_Fieldboard.vue"),
    name: "/fieldkosha_print2",
  },
  {
    path: "/headkosha_print2",
    component: () => import("../views/v_8_kosha/table/list2_temp/print_Headboard.vue"),
    name: "/headkosha_print2",
  },
  {
    path: "/safetyHealth_print1",
    component: () => import("../views/v_8_kosha/table/print/safetyHealth/print1.vue"),
    name: "/safetyHealth_print1",
  },
  {
    path: "/safetyHealth_print2",
    component: () => import("../views/v_8_kosha/table/print/safetyHealth/print2.vue"),
    name: "/safetyHealth_print2",
  },
  {
    path: "/kosha_print3",
    component: () => import("../views/v_8_kosha/table/list3_temp/print.vue"),
    name: "/kosha_print3",
  },
  {
    path: "/risk_print4",
    component: () => import("../views/v_8_kosha/table/list4_temp/print.vue"),
    name: "/risk_print4",
  },
  {
    path: "/kosha_print5",
    component: () => import("../views/v_8_kosha/table/list5_temp/print.vue"),
    name: "/kosha_print5",
  },
  {
    path: "/kosha_print6",
    component: () => import("../views/v_8_kosha/table/list6_temp/print.vue"),
    name: "/kosha_print6",
  },
  {
    path: "/kosha_print8",
    component: () => import("../views/v_8_kosha/table/list8_temp/print.vue"),
    name: "/kosha_print8",
  },
  {
    path: "/kosha_print9",
    component: () => import("../views/v_8_kosha/table/list9_temp/print.vue"),
    name: "/kosha_print9",
  },
  {
    path: "/kosha_print11",
    component: () => import("../views/v_8_kosha/table/list11_temp/print.vue"),
    name: "/kosha_print11",
  },
  {
    path: "/kosha_print30",
    component: () => import("../views/v_8_kosha/table/list_intest/print.vue"),
    name: "/kosha_print30",
  },
  {
    path: "/tkosha_print30",
    component: () => import("../views/v_8_kosha/table/list_intest/print_graph.vue"),
    name: "/tkosha_print30",
  },

  {
    path: "/kosha_print31",
    component: () => import("../views/v_8_kosha/table/list_WS/print.vue"),
    name: "/kosha_print31",
  },
  {
    path: "/kosha_print32",
    component: () => import("../views/v_8_kosha/table/list_legal/print.vue"),
    name: "/kosha_print32",
  },
  {
    path: "/kosha_print33",
    component: () => import("../views/v_8_kosha/table/list_monitor/print.vue"),
    name: "/kosha_print33",
  },
  {
    path: "/tkosha_print33",
    component: () => import("../views/v_8_kosha/table/list_monitor/print_graph.vue"),
    name: "/tkosha_print33",
  },
  {
    path: "/skosha_print33",
    component: () => import("../views/v_8_kosha/table/list_monitor/print_total.vue"),
    name: "/skosha_print33",
  },
  {
    path: "/kosha_print35",
    component: () => import("../views/v_8_kosha/table/list_meeting/print.vue"),
    name: "/kosha_print35",
  },
  {
    path: "/kosha_print_total",
    component: () => import("../views/v_8_kosha/table/list7_temp/print.vue"),
    name: "/kosha_print_total",
  },

  {
    path: "/ca_config",
    component: Layout,
    name: "CA_Config",
    children: [
      {
        path: "branchConf",
        name: "branchConfig",
        component: () => import("../views/v_9_config/ca_config.vue"),
      },
      /*POSITION*******************************************************************************/
      {
        path: "position_list",
        name: "position_list",
        component: () => import("../views/v_9_config/ca_position_conf/position_list.vue"),
      },
      {
        path: "position_create",
        name: "position_create",
        component: () => import("../views/v_9_config/ca_position_conf/position_create.vue"),
      },
   

      /*MEMBER*******************************************************************************/
      //HQ Member
      {
        path: "hq_member_list",
        name: "hq_member_list",
        component: () => import("../views/v_9_config/ca_member_conf/hq_member_list.vue"),
      },
      {
        path: "hq_member_view/:id",
        name: "hq_member_view",
        component: () => import("../views/v_9_config/ca_member_conf/hq_member_view.vue"),
      },
      {
        path: "hq_member_create",
        name: "hq_member_create",
        component: () => import("../views/v_9_config/ca_member_conf/hq_member_create.vue"),
      },
      {
        path: "hq_member_update",
        name: "hq_member_update",
        component: () => import("../views/v_9_config/ca_member_conf/hq_member_update.vue"),
      },
      // HQ stay Member
      {
        path: "hq_member_stay_view",
        name: "hq_member_stay_view",
        component: () => import("../views/v_9_config/ca_member_conf/hq_member_stay_view.vue"),
      },
      {
        path: "hq_member_branch_stay_view",
        name: "hq_member_branch_stay_view",
        component: () =>
          import("../views/v_9_config/ca_member_conf/hq_member_branch_stay_view.vue"),
      },

      //Branch Member
      {
        path: "branch_member_list",
        name: "branch_member_list",
        component: () => import("../views/v_9_config/ca_member_conf/branch_member_list.vue"),
      },
      {
        path: "branch_member_view/:id",
        name: "branch_member_view",
        component: () => import("../views/v_9_config/ca_member_conf/branch_member_view.vue"),
      },
      {
        path: "branch_member_create",
        name: "branch_member_create",
        component: () => import("../views/v_9_config/ca_member_conf/branch_member_create.vue"),
      },
      {
        path: "branch_member_update",
        name: "branch_member_update",
        component: () => import("../views/v_9_config/ca_member_conf/branch_member_update.vue"),
      },
      /*BrunchSite*******************************************************************************/
      {
        path: "branch_list",
        name: "branch_list",
        component: () => import("../views/v_9_config/ca_branch_conf/branch_list.vue"),
      },
      {
        path: "branch_view/:id",
        name: "branch_view",
        component: () => import("../views/v_9_config/ca_branch_conf/branch_view.vue"),
      },
      {
        path: "branch_create",
        name: "branch_create",
        component: () => import("../views/v_9_config/ca_branch_conf/branch_create.vue"),
      },
      {
        path: "branch_update",
        name: "branch_update",
        component: () => import("../views/v_9_config/ca_branch_conf/branch_update.vue"),
      },
      /*CommuteSite*******************************************************************************/
      {
        path: "commute_list",
        name: "commute_list",
        component: () => import("../views/v_9_config/ca_commute_conf/commute_list.vue"),
      },
      {
        path: "commute_create",
        name: "commute_create",
        component: () => import("../views/v_9_config/ca_commute_conf/commute_create.vue"),
      },
      {
        path: "commute_update",
        name: "commute_update",
        component: () => import("../views/v_9_config/ca_commute_conf/commute_update.vue"),
      },
      /* Edu */
      {
        path: "edu_list",
        name: "edu_list",
        component: () => import("../views/v_9_config/ca_edu_conf/edu_list.vue"),
      },

      // setting
      {
        path: "setting_list",
        name: "setting_list",
        component: () => import("../views/v_9_config/ca_setting_conf/setting_list.vue"),
      },

      // field
      {
        path: "field_info",
        name: "field_info",
        component: () => import("../views/v_9_config/ca_fieldinfo_conf/field_info.vue"),
      },
      // 템플릿
      {
        path: "doc_template_list",
        name: "doc_template_list",
        component: () => import("../views/v_9_config/ca_template_conf/doc_template_list.vue"),
      },
      {
        path: "template_list",
        name: "template_list",
        component: () => import("../views/v_9_config/ca_template_conf/template_list.vue"),
      },
      {
        path: "template_create",
        name: "template_create",
        component: () => import("../views/v_9_config/ca_template_conf/template_create.vue"),
      },
      // QR
      {
        path: "registerQR",
        name: "registerQR",
        component: () => import("../views/v_9_config/ca_registerQR_conf/registerQR.vue"),
      },
      // 가이드
      {
        path: "guide_list",
        name: "guide_list",
        component: () => import("../views/v_9_config/ca_guide_conf/guide_list.vue"),
      },
      {
        path: "guide_editor",
        name: "guide_editor",
        component: () => import("../views/v_9_config/ca_guide_conf/guide_editor.vue"),
      },
      // 약관설정
      {
        path: "registerRule_list",
        name: "registerRule_list",
        component: () => import("../views/v_9_config/ca_registerRule_conf/registerRule_list.vue"),
      },
      {
        path: "registerRule_create",
        name: "registerRule_create",
        component: () => import("../views/v_9_config/ca_registerRule_conf/registerRule_create.vue"),
      },
      // 내설정
      {
        path: "my_setting",
        name: "my_setting",
        component: () => import("../views/v_9_config/ca_mysetting_conf/my_setting.vue"),
      },
    ],
    
  },
  {
    path: "/print_register_QR",
    component: () => import("../views/v_9_config/ca_registerQR_conf/printQR.vue"),
    name: "/print_register_QR",
  },
  {
    path: "/ca_approval",
    component: Layout,
    name: "ca_approval",
    children: [
      {
        path: "approval_list",
        name: "approval_list",
        component: () => import("../views/v_10_approval/1_MainList.vue"),
      },
      {
        path: "approval_board",
        name: "approval_board",
        component: () => import("../views/v_10_approval/2_Board.vue"),
      },
      {
        path: "approval_safe_board",
        name: "approval_safe_board",
        component: () => import("../views/v_10_approval/Safe_Board/2_Board.vue"),
      },
      {
        path: "TESTPAGE",
        name: "TESTPAGE",
        component: () => import("../views/v_error/test_page.vue"),
      },
    ],
  },
  {
    path: "/construce_stop",
    component: Layout,
    name: "CA_CONSTRUCT",
    children: [
      {
        path: "construce_stop",
        name: "construce_stop",
        component: () => import("../views/v_x_test/construce_page.vue"),
      },
    ],
  },

  {
    path: "/ca_test",
    name: "testTemplit",
    component: () => import("../views/v_x_test/index.vue"),
    hidden: true,
  },
  // 404 page must be placed at the end !!!
  {
    path: "/:catchAll(.*)",
    redirect: "/ca_home",
    hidden: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


export default router;
